import { HTTP } from "@/plugins/axios.js";
export const auth = {
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/admin-login");
    },
  },
};
