<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="bg-main">
    <el-container>
      <HeaderForm />

      <div v-if="bill" class="payment-detail">
        <div class="payment-section top">
          <div class="payment-header">
            <div class="payment-header-left">
              <el-image
                :src="require('@/assets/images/logo-second.png')"
                class="logo"
              ></el-image>
            </div>

            <div class="payment-header-center">
              <h3>CHIANG MAI UNIVERSITY NEPHROLOGY</h3>
              <h3>HCONFERENCE 2024</h3>
              <h3>December 5-7 , 2024</h3>
              <p>หน่วยวิชาโรคไต ภาควิชาอายุรศาสตร์</p>
              <p>คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่</p>
            </div>

            <div class="payment-header-right">
              <p>(สำหรับผู้สมัคร)</p>
              <p>โปรดเก็บส่วนนี้ไว้เป็นหลักฐานการชำระเงิน</p>
              <p>วันที่ {{ bill.payment.transfer_date | dateNumber }}</p>
            </div>
          </div>
          <div class="payment-header-sub">
            <div class="payment-header-sub-left">
              <p>รหัสชำระเงิน/PAYMENT CODE : {{ bill.payment.payment_code }}</p>

              <div class="payment-header-sub-register-type">
                <p class="label">ประเภทการลงทะเบียน:</p>
                <span class="payment-header-sub-item">
                  <i
                    :class="
                      bill.registerType.register_type === 'single'
                        ? 'far fa-check-square'
                        : 'far fa-square'
                    "
                  ></i>
                  สมัครในนามบุคคล (แบบเดี่ยว)<br />
                  <i
                    :class="
                      bill.registerType.register_type === 'group'
                        ? 'far fa-check-square'
                        : 'far fa-square'
                    "
                  ></i>
                  สมัครในนามหน่วยงาน/องค์กร/บริษัท (แบบกลุ่ม)
                </span>
              </div>
            </div>
            <div class="payment-header-sub-right">
              <p>หน่วยงาน/Institution: {{ bill.registerType.institution }}</p>
            </div>
          </div>
          <div class="payment-table-list">
            <div class="table-header">
              <el-row :gutter="20">
                <el-col :span="10">
                  <h3>รายการ</h3>
                </el-col>
                <el-col :span="7">
                  <h3>จำนวน</h3>
                </el-col>
                <el-col :span="7">
                  <h3>ราคา</h3>
                </el-col>
              </el-row>
            </div>
            <div class="table-body">
              <el-row
                v-for="item in paymentList"
                :key="item.id"
                :gutter="20"
                class="table-item"
              >
                <el-col :span="10">
                  <p>{{ item.name }}</p>
                </el-col>
                <el-col :span="7" class="center">
                  <p>{{ item.number }}</p>
                </el-col>
                <el-col :span="7" class="right">
                  <p>{{ (item.price * item.number) | commaDecimal }}</p>
                </el-col>
              </el-row>
            </div>
            <div class="table-footer">
              <el-row :gutter="20">
                <el-col :span="17">
                  <h3>จำนวนเงินรวม : {{ total | thaiBaht }}</h3>
                </el-col>

                <el-col :span="7" class="right">
                  <h3>{{ total | commaDecimal }}</h3>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="payment-sign">
            <div class="payment-sign-box"></div>
            <div class="payment-sign-item">
              เจ้าหน้าที่ธนาคาร
              <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              <span class="payment-sign-name">(ผู้รับเงิน)</span>
            </div>
          </div>
          <div class="payment-note">
            <span class="payment-note-label"> หมายเหตุ: </span>
            <span class="payment-note-text">
              โปรดตรวจสอบรายละเอียดให้ครบถ้วนก่อนชำระเงิน<br />ทางโครงการขอสงวนสิทธิ์ในการคืนเงินทุกกรณี</span
            >
          </div>
        </div>
        <div class="payment-section bottom">
          <div class="payment-header">
            <div class="payment-header-left">
              <el-image
                :src="require('@/assets/images/logo-second.png')"
                class="logo"
              ></el-image>
            </div>

            <div class="payment-header-center">
              <h3>CHIANG MAI UNIVERSITY NEPHROLOGY</h3>
              <h3>HCONFERENCE 2024</h3>
              <h3>December 5-7 , 2024</h3>
              <p>หน่วยวิชาโรคไต ภาควิชาอายุรศาสตร์</p>
              <p>คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่</p>
            </div>

            <div class="payment-header-right">
              <p>(สำหรับธนาคาร)</p>
              <p>โปรดเรียกเก็บเงินค่าธรรมเนียมจาก</p>
              <p>ผู้ชำระเงิน</p>
            </div>
          </div>
          <div class="payment-header-sub">
            <h3>ใบแจ้งการชำระเงินผ่านธนาคาร / Bank Bill Payment</h3>
          </div>
          <div class="payment-table-list">
            <div class="table-pre-header">
              <el-row :gutter="20">
                <el-col :span="12">
                  <p>
                    ธนาคารไทยพาณิชย์ สาขาคณะแพทยศาสตร์ มช.<br />
                    ชื่อบัญชี หน่วยวิชาโรคไต<br />
                    บัญชี ออมทรัพย์<br />
                    เลขที่บัญชี 566-2-50644-2
                  </p>
                </el-col>
                <el-col :span="12">
                  <p>
                    วันที่ / Date {{ bill.payment.transfer_date | dateNumber
                    }}<br />
                    หน่วยงาน/Institution {{ bill.registerType.institution
                    }}<br />
                    รหัสชำระเงิน/PAYMENT CODE : {{ bill.payment.payment_code
                    }}<br />
                  </p>
                </el-col>
              </el-row>
            </div>
            <div class="table-header">
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>
                    <i
                      :class="
                        bill.payment.payment_type === 'cheque'
                          ? 'far fa-check-square'
                          : 'far fa-square'
                      "
                    ></i>
                    เช็ค / Cheque
                  </p>
                </el-col>
                <el-col :span="6">
                  <p>หมายเลขเช็ค / Cheque No.</p>
                </el-col>
                <el-col :span="6">
                  <p>ชื่อธนาคาร / Bnak-Branch</p>
                </el-col>
                <el-col :span="6">
                  <p>เช็คลงวันที่ / Due Date</p>
                </el-col>
              </el-row>
            </div>
            <div class="table-header">
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>
                    <i
                      :class="
                        bill.payment.payment_type === 'bank_transfer'
                          ? 'far fa-check-square'
                          : 'far fa-square'
                      "
                    ></i>
                    เงินสด / Cash
                  </p>
                </el-col>
                <el-col :span="6"
                  ><p>
                    {{
                      bill.payment.payment_type === "cheque"
                        ? bill.payment.bank_no
                        : "&nbsp;"
                    }}
                  </p></el-col
                >
                <el-col :span="6"
                  ><p>
                    {{
                      bill.payment.payment_type === "cheque"
                        ? `${bill.payment.bank} (${bill.payment.branch})`
                        : "&nbsp;"
                    }}
                  </p></el-col
                >
                <el-col :span="6"
                  ><p v-if="bill.payment.payment_type === 'cheque'">
                    {{ bill.payment.transfer_date | dateNumber }}
                  </p>
                  <p v-else>&nbsp;</p></el-col
                >
              </el-row>
            </div>
            <div class="table-footer border-bottom">
              <el-row :gutter="20">
                <el-col :span="9">
                  <p>จำนวนเงิน(บาท) / Amount (Baht)</p>
                </el-col>

                <el-col :span="15" class="right">
                  <p>{{ total | commaDecimal }}</p>
                </el-col>
              </el-row>
            </div>
            <div class="table-footer">
              <el-row :gutter="20">
                <el-col :span="9">
                  <p>จำนวนเงินเป็นตัวอักษร / Amount in Words</p>
                </el-col>

                <el-col :span="15" class="right">
                  <p>({{ total | thaiBaht }})</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="payment-sign">
            <div class="payment-sign-box">
              <div class="payment-sign-item">
                ผู้นำฝาก
                <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              </div>
              <div class="payment-sign-item">
                โทร.
                <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              </div>
            </div>
            <div class="payment-sign-item">
              เจ้าหน้าที่ธนาคาร
              <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              <span class="payment-sign-name">(ผู้รับเงิน)</span>
            </div>
          </div>
          <div class="payment-note">
            <span class="payment-note-label"> หมายเหตุ: </span>
            <span class="payment-note-text">
              มีค่าธรรมเนียมการชำระเงินตามระเบียบของธนาคาร</span
            >
          </div>
        </div>
      </div>
      <div v-if="bill" class="action disabled-print">
        <el-button @click="print" type="success">พิมพ์เอกสาร</el-button>
        <el-button
          @click="$router.push(`/payment-confirm?code=${$route.params.code}`)"
          type="primary"
          >ยื่นหลักฐานการชำระเงิน</el-button
        >
      </div>

      <div v-if="!bill" class="not-found">
        <h1>ไม่มีข้อมูล, กรุณาตรวจสอบรหัสชำระเงินอีกครั้ง</h1>
      </div>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import HeaderForm from "@/layout/HeaderForm";
import Footer from "@/layout/Footer";

import { HTTP } from "@/plugins/axios.js";

export default {
  name: "PaymentPage",
  components: {
    Footer,
    HeaderForm,
  },
  computed: {
    total() {
      let total = 0;
      this.paymentList.map((item) => {
        total += item.price * item.number;
      });
      return total;
    },
  },
  data() {
    return {
      paymentList: [
        {
          id: "member",
          name: "Doctor, Nurse / แพทย์, พยาบาล",
          price: 3500,
          number: 0,
        },
      ],
      bill: "",
      fullscreenLoading: true,
    };
  },
  mounted() {
    this.fetchPayment();
  },
  methods: {
    print() {
      window.print();
    },
    async fetchPayment() {
      let res = await HTTP.get(`/payment/get/${this.$route.params.code}`);

      if (res.data.success) {
        if (res.data.data.register) {
          this.bill = res.data.data;

          this.paymentList = this.paymentList.map((item) => {
            let filter = res.data.data.register.filter(
              (f) => f.registration_fees === item.id
            );
            item.number = filter.length;
            return item;
          });
        }
      }

      this.fullscreenLoading = false;
    },
  },
};
</script>
