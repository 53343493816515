import initState from "./initState";
export default {
  setLanguage(state, data) {
    state.language = data;
  },
  setToken(state, data) {
    state.token = data;
  },
  logout(state) {
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
