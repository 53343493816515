export const status = {
  computed: {
    registerStatus() {
      return this.$store.state.registerStatus;
    },
    paymentStatus() {
      return this.$store.state.paymentStatus;
    },
    paymentConfirmStatus() {
      return this.$store.state.paymentConfirmStatus;
    },
    paymentUpdateStatus() {
      return this.$store.state.paymentConfirmStatus.filter(
        (f) => f.name != "all"
      );
    },
    reservationStatus() {
      return this.$store.state.reservationStatus;
    },
    reservationStatusAction() {
      return this.$store.state.reservationStatus.filter(
        (f) => f.name !== "all"
      );
    },
  },
  methods: {
    getStatusColor(name) {
      const find = this.registerStatus.find((f) => f.name === name);

      return typeof find !== "undefined" ? find.color : "";
    },
    getpaymentConfirmStatusColor(status) {
      const find = this.paymentConfirmStatus.find((f) => f.name === status);

      return typeof find !== "undefined" ? find.color : "";
    },
    getpaymentConfirmStatusLabel(status) {
      const find = this.paymentConfirmStatus.find((f) => f.name === status);

      return typeof find !== "undefined" ? find.label : "";
    },
    getPaymentStatusColor(name) {
      const find = this.paymentStatus.find((f) => f.name === name);

      return typeof find !== "undefined" ? find.color : "";
    },
    getReservationStatusColor(label) {
      const find = this.reservationStatus.find((f) => f.label === label);

      return typeof find !== "undefined" ? find.color : "";
    },
  },
};
