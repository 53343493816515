<template>
  <AdminTemplate class="admin-register-list">
    <div class="admin-header">
      <h1>ข้อมูลการลงทะเบียน</h1>
    </div>
    <el-row :gutter="6">
      <el-col :span="10">
        <el-card class="admin-search-box">
          <el-form :model="form" ref="form">
            <h3>ค้นหาข้อมูลการลงทะเบียน</h3>
            <el-row :gutter="12">
              <el-col :span="12">
                <el-form-item label="ค้นหา" prop="search">
                  <el-input
                    v-model="form.search"
                    placeholder="ค้นหาจากชื่อ,นามสกุล,รหัสลงทะเบียน"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="สถานะ" prop="status">
                  <el-select
                    v-model="form.status"
                    placeholder="สถานะ"
                    class="width-100"
                  >
                    <div slot="prefix">
                      <i
                        class="fa-solid fa-circle"
                        :style="`color:${getStatusColor(form.status)};`"
                      ></i>
                    </div>
                    <el-option
                      v-for="item in registerStatus"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    >
                      <span
                        ><i
                          class="fa-solid fa-circle"
                          :style="`color:${item.color};margin-right:5px`"
                        ></i>
                        {{ item.name }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24"
                ><div class="float-left">
                  <el-button
                    @click="exportRegister"
                    :loading="loadingExport"
                    icon="el-icon-document-delete"
                    size="medium"
                    >รายงาน</el-button
                  >
                  <el-button
                    @click="exportRegisterGroup"
                    :loading="loadingExportGroup"
                    icon="el-icon-document-delete"
                    size="medium"
                    >รายงานแบบกลุ่ม</el-button
                  >
                </div>
                <div class="float-right">
                  <!-- <el-button type="info" plain size="medium"
                    >แสกน <i class="fa-solid fa-expand"></i
                  ></el-button> -->
                  <el-button
                    @click="search"
                    :loading="loadingList"
                    type="success"
                    size="medium"
                    >ค้นหา</el-button
                  >
                </div></el-col
              >
            </el-row>
          </el-form>
        </el-card>

        <div class="admin-list-box">
          <el-card>
            <h3>รายการลงทะเบียน</h3>
            <div v-loading="loadingList" class="user-list">
              <el-row
                :gutter="20"
                v-for="item in users"
                :key="item.id"
                class="user-item"
                @click="fetchRegisteredDetail(item.id)"
              >
                <el-col :span="19" class="content-box">
                  <div class="content-detail-box">
                    <i
                      :title="item.status"
                      class="fa-solid fa-circle"
                      :style="`color:${getStatusColor(item.status)};`"
                    ></i>
                    <div>
                      <p>
                        {{
                          `${item.prefix_en}${item.first_name_en} ${item.last_name_en}`
                        }}
                      </p>
                      <p>
                        {{
                          `${item.prefix}${item.first_name} ${item.last_name}`
                        }}
                      </p>
                      <p>
                        {{ item.create_date | dateTHShort }}
                        <el-tag type="info">{{ item.register_code }}</el-tag>
                      </p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5" class="action-box">
                  <el-button
                    @click="fetchRegisteredDetail(item.id)"
                    type="info"
                    icon="el-icon-user-solid"
                    size="mini"
                    circle
                  ></el-button>
                  <!-- <el-popconfirm
                    title="คุณต้องการลบผู้สมัครนี้?"
                    confirm-button-text="ใช่"
                    cancel-button-text="ไม่ใช่"
                    confirm-button-type="info"
                    @confirm="deleteRegister(item.id)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      circle
                    ></el-button>
                  </el-popconfirm> -->
                </el-col>
              </el-row>
              <div v-if="total > 0" class="pagination-box">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :current-page.sync="page"
                  @current-change="handleCurrentChange"
                  :page-size="limit"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col v-if="detail" v-loading="loadingDetail" :span="14">
        <el-row :gutter="12"
          ><el-col :span="12">
            <el-card class="user-detail-box">
              <h3>ข้อมูลผู้สมัคร : #{{ detail.register.register_code }}</h3>
              <p>
                {{
                  `${detail.register.prefix_en} ${detail.register.first_name_en} ${detail.register.last_name_en}`
                }}
              </p>
              <p>
                {{
                  `${detail.register.prefix} ${detail.register.first_name} ${detail.register.last_name}`
                }}
              </p>
              <el-divider></el-divider>
              <h4>เข้าชมวีดีโอ</h4>
              <p><b>User:</b> {{ detail.registerType.company_email }}</p>
              <p><b>Password:</b> {{ detail.register.password }}</p>
              <el-divider></el-divider>
              <div v-if="detail.registerType.register_type === 'group'">
                <p>
                  <b>ประเภทการสมัคร:</b> สมัครในนามหน่วยงาน/องค์กร/บริษัท
                  (แบบกลุ่ม)
                </p>
                <p>
                  <b>หน่วยงาน/องค์กร/บริษัท:</b>
                  {{ detail.registerType.institution }}
                </p>

                <div class="display-flex">
                  <p class="flex-1">
                    <b>City:</b> {{ detail.registerType.company_city }}
                  </p>
                  <p class="flex-1">
                    <b>Country:</b> {{ detail.registerType.company_country }}
                  </p>
                </div>

                <div class="display-flex">
                  <p class="flex-1">
                    <b>Zip:</b> {{ detail.registerType.company_zipcode }}
                  </p>
                  <p class="flex-1">
                    <b>Telephone:</b>
                    {{ detail.registerType.company_telephone }}
                  </p>
                </div>
                <p><b>Email:</b> {{ detail.registerType.ompany_email }}</p>
                <el-divider></el-divider>
              </div>
              <p v-if="detail.registerType.register_type === 'single'">
                <b>ประเภทการสมัคร:</b> สมัครในนามบุคคล (แบบเดี่ยว)
              </p>
              <p>
                <b>เลขทีประกอบวิชาชีพ:</b> {{ detail.register.no_professional }}
              </p>
              <p>
                <b>บ้าน:</b>
                {{
                  detail.register.address_type === "home" ? "บ้าน" : "ออฟฟิศ"
                }}
              </p>
              <div class="display-flex">
                <p class="flex-1">
                  <b>District:</b> {{ detail.register.district }}
                </p>
                <p class="flex-1">
                  <b>Amphoe:</b> {{ detail.register.amphoe }}
                </p>
              </div>
              <div class="display-flex">
                <p class="flex-1"><b>City:</b> {{ detail.register.city }}</p>
                <p class="flex-1">
                  <b>Country:</b> {{ detail.register.country }}
                </p>
              </div>
              <div class="display-flex">
                <p class="flex-1"><b>Zip:</b> {{ detail.register.zipcode }}</p>
                <p class="flex-1">
                  <b>Telephone:</b> {{ detail.register.telephone }}
                </p>
              </div>
              <p><b>Email:</b> {{ detail.register.email }}</p>
              <p>
                <b>หน่วยงาน/องค์กร/บริษัท:</b> {{ detail.register.institution }}
              </p>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="payment-detail-box"
              ><h3>ค่าลงทะเบียน</h3>
              <p>
                <b>ประเภท:</b>
                {{
                  detail.register.registration_fees === "member"
                    ? "แพทย์, พยาบาล"
                    : ""
                }}
              </p>
              <p>
                <b>ค่าลงทะเบียน (บาท):</b>
                {{
                  detail.register.registration_fees === "member" ? "3,500" : ""
                }}
              </p></el-card
            ><el-card v-if="detail.payment" class="payment-confirm-detail-box"
              ><h3>ข้อมูลการชำระเงิน</h3>
              <p><b>เลขใบ Payment:</b> #{{ detail.payment.payment_code }}</p>
              <h4>วิธีการชำระเงิน</h4>
              <p>
                <i class="fa-regular fa-circle-check"></i>
                {{
                  detail.payment.payment_type === "bank_transfer"
                    ? "โอนเงินเข้าบัญชี"
                    : "เช็คธนาคาร"
                }}
              </p>
              <el-divider></el-divider>
              <p>
                <b>จำนวนเงิน:</b> {{ detail.payment.total_price | comma }} บาท
              </p>
              <!-- <el-button
                v-if="
                  detail.paymentConfirm &&
                  detail.paymentConfirm.status === 'รอตรวจสอบ'
                "
                type="success"
                >อนุมัติชำระเงิน</el-button
              > -->
            </el-card>
          </el-col></el-row
        >
      </el-col>
    </el-row>
  </AdminTemplate>
</template>

<script>
import AdminTemplate from "@/template/AdminTemplate";
import { status } from "@/maxins/status";
import { auth } from "@/maxins/auth";
import { HTTP } from "@/plugins/axios.js";

export default {
  name: "AdminRegistered",
  components: {
    AdminTemplate,
  },
  props: {
    msg: String,
  },
  computed: {},
  mixins: [status, auth],
  data() {
    return {
      form: {
        search: "",
        status: "ทั้งหมด",
      },
      users: [],
      total: 0,
      limit: 10,
      page: 1,
      loadingList: true,
      loadingDetail: true,
      loadingExport: false,
      loadingExportGroup: false,
      detail: null,
      fileExportURL: process.env.VUE_APP_BASE_EXPORT_FILE,
    };
  },
  mounted() {
    this.fetchRegistered();
  },
  methods: {
    search() {
      this.loadingList = true;
      this.page = 1;
      this.fetchRegistered();
    },
    handleCurrentChange(val) {
      this.loadingList = true;
      this.page = val;
      this.fetchRegistered();
    },
    async fetchRegistered() {
      try {
        let obj = {
          search: this.form.search,
          status: this.form.status === "ทั้งหมด" ? "" : this.form.status,
          page: this.page,
          limit: this.limit,
        };
        let res = await HTTP.post(`/register/get`, obj);

        if (res.data.success) {
          this.total = res.data.data.total;
          this.users = res.data.data.result;
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.logout();
        }
      } finally {
        this.loadingList = false;
      }
    },
    async fetchRegisteredDetail(id) {
      this.loadingDetail = true;
      let res = await HTTP.get(`/register/by/${id}`);

      if (res.data.success) {
        this.detail = res.data.data;
      }

      this.loadingDetail = false;
    },
    async deleteRegister(id) {
      this.loadingList = true;
      let res = await HTTP.delete(`/register/by/${id}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchRegistered();
      }

      this.loadingList = false;
    },
    async exportRegisterGroup() {
      this.loadingExportGroup = true;
      let res = await HTTP.post(`/register/export-group`);

      if (res.data.success) {
        this.$message({
          message: "Export ข้อมูลสำเร็จ",
          type: "success",
        });

        this.dowloadFileExport(res.data.data);
        setTimeout(() => {
          this.deleteFileExport(res.data.data);
        }, 500);
      }

      this.loadingExportGroup = false;
    },
    async exportRegister() {
      this.loadingExport = true;
      let res = await HTTP.post(`/register/export`);

      if (res.data.success) {
        this.$message({
          message: "Export ข้อมูลสำเร็จ",
          type: "success",
        });

        this.dowloadFileExport(res.data.data);
        setTimeout(() => {
          this.deleteFileExport(res.data.data);
        }, 500);
      }

      this.loadingExport = false;
    },
    dowloadFileExport(filename) {
      const link = document.createElement("a");

      link.href = `${this.fileExportURL}/${filename}`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async deleteFileExport(filename) {
      let obj = {
        filename: filename,
      };
      await HTTP.post(`/register/delete-export`, obj);
    },
  },
};
</script>
