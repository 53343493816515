export default {
  menu: {
    home: "Home",
    video: "Video",
    register: "Register",
    accommodation: "Accommodation",
    schedule: "Schedule",
    contactUs: "Contact Us",
    downloadDocument: "Download document",
  },
  register: {
    personal: "Personal apply",
    organization: "Organization/Company apply",
    findPaymentInvoice: "Find Payment invoice",
    fillFindPaymentInvoice: "Fill Payment invoice code",
    submitProofOfPayment: "Submit proof of payment",
    fillSubmitProofOfPayment: "Fill Submit proof of payment",
  },
  accommodation: {
    btnBooking: "Booking",
    findPaymentInvoice: "Find Payment invoice",
    fillFindPaymentInvoice: "Fill Payment invoice code",
    submitProofOfPayment: "Submit proof of payment",
    fillSubmitProofOfPayment: "Fill Submit proof of payment",
  },
  message: {
    btnSearch: "Search",
    btnCancel: "Cancel",
    btnRegister: "Register",
  },
};
