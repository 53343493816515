import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import initState from "./initState";

Vue.use(Vuex);

const plugins = [
  createPersistedState({
    key: "rb2024",
    storage: null,
  }),
];

export default new Vuex.Store({
  state: initState(),
  getters,
  mutations,
  actions,
  plugins,
});
