<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <h2 class="text-left text-dark">Payment การชําระเงิน</h2>
      <div class="bank-account text-dark">
        <p>
          ชื่อบัญชี หน่วยวิชาโรคไต เลขที่บัญชี 566-2-50644-2 ธนาคารไทยพาณิชย์
          สาขา คณะแพทยศาสตร์ มช. บัญชี ออมทรัพย์
        </p>
        <p>
          Payments can be made in US$ by bank transfer to the following bank
          account: Bank account No. 566-2-50644-2 The Siam Commercial Bank
          Public Company Limited, Faculty of Medicine Chiang Mai branch,Chiang
          Mai 50200, Thailand
        </p>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Reservation-PaymentFormComponent",
  data() {
    return {};
  },

  methods: {},
};
</script>
