<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-form-item
        label="Organization,Company หน่วยงาน, สถาบัน"
        prop="companyName"
      >
        <el-input
          v-model="form.companyName"
          placeholder="Organization,Company หน่วยงาน, สถาบัน"
          @input="updateFormCompany('companyName', form.companyName)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="24">
      <p class="description">
        (โปรดระบุ บ้านเลขที่,หมู่บ้าน,ซอย หรือรายละเอียดอื่นๆให้ครบถ้วน)
      </p>
      <el-form-item prop="address">
        <el-input
          v-model="form.address"
          placeholder="Address / ที่อยู่"
          @input="updateFormCompany('address', form.address)"
          class="width-100"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="district">
        <el-autocomplete
          class="width-100"
          popper-class="popper-auto-address"
          v-model="form.district"
          :fetch-suggestions="
            (queryString, cb) => querySearch(queryString, cb, 'district')
          "
          placeholder="District / ตำบล"
          @select="handleSelectAddress"
          @input="updateFormCompany('district', form.district)"
        ></el-autocomplete>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="amphoe">
        <el-autocomplete
          class="width-100"
          popper-class="popper-auto-address"
          v-model="form.amphoe"
          :fetch-suggestions="
            (queryString, cb) => querySearch(queryString, cb, 'amphoe')
          "
          placeholder="Amphoe / อำเภอ"
          @select="handleSelectAddress"
          @input="updateFormCompany('amphoe', form.amphoe)"
        ></el-autocomplete>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="city">
        <el-autocomplete
          class="width-100"
          popper-class="popper-auto-address"
          v-model="form.city"
          :fetch-suggestions="
            (queryString, cb) => querySearch(queryString, cb, 'city')
          "
          placeholder="City / จังหวัด"
          @select="handleSelectAddress"
          @input="updateFormCompany('city', form.city)"
        ></el-autocomplete>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="state">
        <el-input
          v-model="form.state"
          placeholder="State / รัฐ"
          @input="updateFormCompany('state', form.state)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="country">
        <el-input
          v-model="form.country"
          placeholder="Country / ประเทศ"
          @input="updateFormCompany('country', form.country)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="zipcode">
        <el-autocomplete
          class="width-100"
          popper-class="popper-auto-address"
          v-model="form.zipcode"
          :fetch-suggestions="
            (queryString, cb) => querySearch(queryString, cb, 'zipcode')
          "
          placeholder="Zip / รหัสไปรษณี"
          @select="handleSelectAddress"
          @input="updateFormCompany('zipcode', form.zipcode)"
        ></el-autocomplete>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="tel">
        <el-input
          v-model="form.tel"
          placeholder="Tel / โทรศัพท์"
          @input="updateFormCompany('tel', form.tel)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8" prop="fax">
      <el-form-item>
        <el-input
          v-model="form.fax"
          placeholder="Fax / โทรสาร"
          @input="updateFormCompany('fax', form.fax)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item prop="email">
        <el-input
          v-model="form.email"
          placeholder="E-mail / อีเมล"
          @input="updateFormCompany('email', form.email)"
        ></el-input>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
import {
  searchAddressByDistrict,
  searchAddressByAmphoe,
  searchAddressByProvince,
  searchAddressByZipcode,
} from "thai-address-database";

export default {
  name: "CompanyComponent",
  data() {
    return {
      form: {
        companyName: "",
        address: "",
        district: "",
        amphoe: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        tel: "",
        fax: "",
        email: "",
      },
    };
  },

  methods: {
    querySearch(queryString, cb, type) {
      var results = [];

      switch (type) {
        case "district":
          results = searchAddressByDistrict(queryString);
          break;
        case "amphoe":
          results = searchAddressByAmphoe(queryString);
          break;
        case "city":
          results = searchAddressByProvince(queryString);
          break;
        case "zipcode":
          results = searchAddressByZipcode(queryString);
          break;
        default:
          break;
      }

      var resultsConvert = results.map((item) => {
        let value = `${item.district} » ${item.amphoe} » ${item.province} » ${item.zipcode}`;
        return { value: value, data: item };
      });

      cb(resultsConvert);
    },
    handleSelectAddress(value) {
      this.form.district = value.data.district;
      this.form.amphoe = value.data.amphoe;
      this.form.city = value.data.province;
      this.form.zipcode = value.data.zipcode;

      this.updateFormCompany("district", this.form.district);
      this.updateFormCompany("amphoe", this.form.amphoe);
      this.updateFormCompany("city", this.form.city);
      this.updateFormCompany("zipcode", this.form.zipcode);
    },
    updateFormCompany(key, value) {
      this.$emit("updateFormCompany", key, value);
    },
  },
};
</script>
