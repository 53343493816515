<template>
  <div class="width-100">
    <div class="form-info">
      <div class="header-form-info">
        <h1>Reservation Information ข้อมูลการจอง</h1>
        <p>If you don't have any information, Please fill - in the blanks.</p>
        <p>(หากคุณไม่มีข้อมูล โปรดกรอกเครื่องหมาย - ในช่องว่าง)</p>
      </div>

      <el-row v-if="formInfo" :gutter="20">
        <el-col :sm="24" :md="6">
          <el-form-item
            prop="prefixTH"
            :rules="[
              {
                required: true,
                message: 'กรุณาเลือกคำนำหน้านาม',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="formInfo.prefixTH"
              placeholder="คำนำหน้านาม"
              @change="updateFormInfo('prefixTH', formInfo.prefixTH)"
              class="width-100"
            >
              <el-option
                v-for="item in prefixs"
                :key="item.nameTH"
                :label="item.nameTH"
                :value="item.nameTH"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            prop="nameTH"
            :rules="[
              {
                required: true,
                message: 'กรุณากรอกชื่อ',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.nameTH"
              placeholder="ชื่อ"
              @input="updateFormInfo('nameTH', formInfo.nameTH)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            prop="lastNameTH"
            :rules="[
              {
                required: true,
                message: 'กรุณากรอกนามสกุล',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.lastNameTH"
              placeholder="นามสกุล"
              @input="updateFormInfo('lastNameTH', formInfo.lastNameTH)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="6">
          <el-form-item
            prop="prefixEN"
            :rules="[
              {
                required: true,
                message: 'Please select Prefix',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="formInfo.prefixEN"
              placeholder="Prefix"
              @change="updateFormInfo('prefixEN', formInfo.prefixEN)"
              class="width-100"
            >
              <el-option
                v-for="item in prefixs"
                :key="item.nameEN"
                :label="item.nameEN"
                :value="item.nameEN"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            prop="nameEN"
            :rules="[
              {
                required: true,
                message: 'Please input Name',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.nameEN"
              placeholder="Name"
              @input="updateFormInfo('nameEN', formInfo.nameEN)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            prop="lastNameEN"
            :rules="[
              {
                required: true,
                message: 'Please input Last Name',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.lastNameEN"
              placeholder="Last Name"
              @input="updateFormInfo('lastNameEN', formInfo.lastNameEN)"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item
            label="Organization,Company หน่วยงาน, สถาบัน"
            prop="companyName"
            :rules="[
              {
                required: true,
                message: 'Please input Organization,Company',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.companyName"
              placeholder="Organization,Company หน่วยงาน, สถาบัน"
              @input="updateFormInfo('companyName', formInfo.companyName)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <p class="description"></p>
          <el-form-item
            prop="addressType"
            :rules="[
              {
                required: true,
                message: 'Please input Address Type',
                trigger: 'blur',
              },
            ]"
          >
            <el-select
              v-model="formInfo.addressType"
              placeholder="Address Type / ประเภทที่อยู่"
              @change="updateFormInfo('addressType', formInfo.addressType)"
              class="width-100"
            >
              <el-option
                v-for="item in addressTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="16">
          <p class="description">
            (โปรดระบุ บ้านเลขที่,หมู่บ้าน,ซอย หรือรายละเอียดอื่นๆให้ครบถ้วน)
          </p>
          <el-form-item
            prop="address"
            :rules="[
              {
                required: true,
                message: 'Please input Address',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.address"
              placeholder="Address / ที่อยู่"
              @input="updateFormInfo('address', formInfo.address)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            prop="district"
            :rules="[
              {
                required: true,
                message: 'Please input District',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="formInfo.district"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'district')
              "
              placeholder="District / ตำบล"
              @select="(value) => handleSelectAddress(value)"
              @input="updateFormInfo('district', formInfo.district)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            prop="amphoe"
            :rules="[
              {
                required: true,
                message: 'Please input Amphoe',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="formInfo.amphoe"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'amphoe')
              "
              placeholder="Amphoe / อำเภอ"
              @select="(value) => handleSelectAddress(value)"
              @input="updateFormInfo('amphoe', formInfo.amphoe)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            prop="city"
            :rules="[
              {
                required: true,
                message: 'Please input City',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="formInfo.city"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'city')
              "
              placeholder="City / จังหวัด"
              @select="(value) => handleSelectAddress(value)"
              @input="updateFormInfo('city', formInfo.city)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item prop="state">
            <el-input
              v-model="formInfo.state"
              placeholder="State / รัฐ"
              @input="updateFormInfo('state', formInfo.state)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            prop="city"
            :rules="[
              {
                required: true,
                message: 'Please input Country',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.country"
              placeholder="Country / ประเทศ"
              @input="updateFormInfo('country', formInfo.country)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            prop="zipcode"
            :rules="[
              {
                required: true,
                message: 'Please input Zip code',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="formInfo.zipcode"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'zipcode')
              "
              placeholder="Zip / รหัสไปรษณี"
              @select="(value) => handleSelectAddress(value)"
              @input="updateFormInfo('zipcode', formInfo.zipcode)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            prop="tel"
            :rules="[
              {
                required: true,
                message: 'Please input Tel',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formInfo.tel"
              placeholder="Tel / โทรศัพท์"
              @input="updateFormInfo('tel', formInfo.tel)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8" prop="fax">
          <el-form-item>
            <el-input
              v-model="formInfo.fax"
              placeholder="Fax / โทรสาร"
              @input="updateFormInfo('fax', formInfo.fax)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            prop="email"
            :rules="[
              {
                required: true,
                message: 'Please input Email',
                trigger: 'blur',
              },
              { validator: checkEmail, trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="formInfo.email"
              placeholder="E-mail / อีเมล"
              @input="updateFormInfo('email', formInfo.email)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="room-reservation-box">
            <p class="label-reservation">Room Reservation, การจองห้องพัก</p>
            <div class="room-reservation-table">
              <div class="hotel-name">
                <p>The Empress Chiang Mai</p>
              </div>
              <div class="room-reservation-room-info">
                <div
                  v-for="item in reservationFees"
                  :key="item.id"
                  class="room-reservation-item"
                >
                  <h4>{{ item.name }}</h4>
                  <div class="rome-type-item">
                    <p>
                      - Single/พักเดี่ยว ราคา
                      {{ item.singlePrice | commaBath }} บาท
                    </p>
                    <el-input-number
                      size="small"
                      v-model="formInfo[`${item.id}Room`]"
                      @input="
                        updateFormInfo(
                          `${item.id}Room`,
                          formInfo[`${item.id}Room`]
                        )
                      "
                    ></el-input-number>
                  </div>
                  <div class="rome-type-item">
                    <p>
                      - Twin/พักคู่ ราคา
                      {{ item.twinPrice | commaBath }} บาท
                    </p>
                    <el-input-number
                      size="small"
                      v-model="formInfo[`${item.id}TwinRoom`]"
                      @input="
                        updateFormInfo(
                          `${item.id}TwinRoom`,
                          formInfo[`${item.id}TwinRoom`]
                        )
                      "
                    ></el-input-number>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item prop="checkInDate">
            <el-date-picker
              v-model="formInfo.checkInDate"
              @input="updateFormInfo('checkInDate', formInfo.checkInDate)"
              type="date"
              placeholder="Check in date / วันที่เช็คอิน"
              class="width-100"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item prop="checkOutDate">
            <el-date-picker
              v-model="formInfo.checkOutDate"
              @input="updateFormInfo('checkOutDate', formInfo.checkOutDate)"
              type="date"
              placeholder="Check out date / วันที่เช็คเอาท์"
              class="width-100"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="6">
          <span>จำนวน {{ totalNight }} คืน</span>
        </el-col>
        <el-col :sm="24" :md="18">
          <el-form-item prop="arrivalDate">
            <el-date-picker
              v-model="formInfo.arrivalDate"
              @change="updateFormInfo('arrivalDate', formInfo.arrivalDate)"
              type="date"
              placeholder="Arrival date / วันที่เดินทางมาถึง"
              class="width-100"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="6">
          <el-form-item prop="arrivalFightNo">
            <el-input
              v-model="formInfo.arrivalFightNo"
              @input="updateFormInfo('arrivalFightNo', formInfo.arrivalFightNo)"
              placeholder="Fight No / เที่ยวบิน"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="18">
          <el-form-item prop="departureDate">
            <el-date-picker
              v-model="formInfo.departureDate"
              @change="updateFormInfo('departureDate', formInfo.departureDate)"
              type="date"
              placeholder="Departure date / วันที่ออกเดินทาง"
              class="width-100"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="6">
          <el-form-item prop="departureFightNo">
            <el-input
              v-model="formInfo.departureFightNo"
              @input="
                updateFormInfo('departureFightNo', formInfo.departureFightNo)
              "
              placeholder="Fight No / เที่ยวบิน"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  searchAddressByDistrict,
  searchAddressByAmphoe,
  searchAddressByProvince,
  searchAddressByZipcode,
} from "thai-address-database";

export default {
  name: "Reservation-InfoFormComponent",
  props: ["formInfoProp"],
  computed: {
    totalNight() {
      if (
        !this.formInfo ||
        !this.formInfo.checkInDate ||
        !this.formInfo.checkOutDate
      ) {
        return 0;
      }

      let start = new Date(this.formInfo.checkInDate).setHours(0, 0, 0, 0);
      let end = new Date(this.formInfo.checkOutDate).setHours(0, 0, 0, 0);

      let day = (end - start) / 86400000;

      return day;
    },
  },
  data() {
    return {
      formInfo: null,
      prefixs: [
        { nameTH: "นพ.", nameEN: "Prof." },
        { nameTH: "พญ.", nameEN: "Dr." },
        { nameTH: "นาย", nameEN: "Mr." },
        { nameTH: "นาง", nameEN: "Ms." },
        { nameTH: "นางสาว", nameEN: "Mrs." },
      ],
      reservationFees: [
        {
          id: "superior",
          name: "Superior Room",
          singlePrice: 2000,
          twinPrice: 2000,
        },
        {
          id: "deluxe",
          name: "Deluxe Room",
          singlePrice: 2600,
          twinPrice: 2600,
        },
        {
          id: "keyMan",
          name: "Key man Room",
          singlePrice: 3200,
          twinPrice: 3200,
        },
        {
          id: "extraBed",
          name: "Extra Bed",
          singlePrice: 1000,
          twinPrice: 1000,
        },
      ],
      addressTypes: [
        { id: "home", name: "Home / บ้าน" },
        { id: "office", name: "Office / สำนักงาน" },
      ],
    };
  },
  watch: {
    formInfoProp() {
      this.formInfo = this.formInfoProp;
    },
  },
  mounted() {
    this.formInfo = this.formInfoProp;
  },
  methods: {
    checkEmail(rule, value, callback) {
      if (!value) {
        return callback(new Error("Please input email"));
      } else {
        let regx = /^[\wก-ฮ-.]+@([\wก-ฮ-]+.)+[\wก-ฮ-]{2,4}$/g;
        let match = regx.test(value);
        if (match) {
          callback();
        } else {
          callback(new Error("Please input correct email"));
        }
      }
    },
    querySearch(queryString, cb, type) {
      var results = [];

      switch (type) {
        case "district":
          results = searchAddressByDistrict(queryString);
          break;
        case "amphoe":
          results = searchAddressByAmphoe(queryString);
          break;
        case "city":
          results = searchAddressByProvince(queryString);
          break;
        case "zipcode":
          results = searchAddressByZipcode(queryString);
          break;
        default:
          break;
      }

      var resultsConvert = results.map((item) => {
        let value = `${item.district} » ${item.amphoe} » ${item.province} » ${item.zipcode}`;
        return { value: value, data: item };
      });

      cb(resultsConvert);
    },
    handleSelectAddress(value) {
      this.updateFormInfo("district", value.data.district);
      this.updateFormInfo("amphoe", value.data.amphoe);
      this.updateFormInfo("city", value.data.province);
      this.updateFormInfo("zipcode", value.data.zipcode);
    },
    updateFormInfo(key, value) {
      this.$emit("updateFormInfo", key, value);
    },
  },
};
</script>
