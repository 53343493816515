<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  created() {
    this.$i18n.locale = this.language;
  },
};
</script>
