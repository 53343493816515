export default {
  menu: {
    home: "หน้าหลัก",
    video: "วีดีโอ",
    register: "ลงทะเบียน",
    accommodation: "ที่พัก",
    schedule: "กำหนดการ",
    contactUs: "ติดต่อเรา",
    downloadDocument: "ดาวน์โหลดเอกสาร",
  },
  register: {
    personal: "บุคคล (แบบเดี่ยว)​​",
    organization: "หน่วยงาน/องค์กร/บริษัท (แบบกลุ่ม)​",
    findPaymentInvoice: "ค้นหาใบแจ้งชำระเงิน",
    fillFindPaymentInvoice: "กรอกเลขใบแจ้งชำระเงิน",
    submitProofOfPayment: "ยื่นหลักฐานการชำระเงิน",
    fillSubmitProofOfPayment: "กรอกเลขหลักฐานการชำระเงิน",
  },
  accommodation: {
    btnBooking: "จอง",
    findPaymentInvoice: "ค้นหาใบแจ้งชำระเงิน",
    fillFindPaymentInvoice: "กรอกเลขใบแจ้งชำระเงิน",
    submitProofOfPayment: "ยื่นหลักฐานการชำระเงิน",
    fillSubmitProofOfPayment: "กรอกเลขหลักฐานการชำระเงิน",
  },
  message: {
    btnSearch: "ค้นหา",
    btnCancel: "ยกเลิก",
    btnRegister: "ลงทะเบียน",
  },
};
