var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AdminTemplate',{staticClass:"admin-register-list"},[_c('div',{staticClass:"admin-header"},[_c('h1',[_vm._v("ยืนยันการชำระเงิน")])]),_c('el-row',{attrs:{"gutter":6}},[_c('el-col',{attrs:{"span":10}},[_c('el-card',{staticClass:"admin-search-box"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('h3',[_vm._v("ค้นหาข้อมูลการลงทะเบียน")]),_c('el-row',{attrs:{"gutter":12}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"ค้นหา","prop":"search"}},[_c('el-input',{attrs:{"placeholder":"ค้นหาจากรหัสลงทะเบียน"},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"สถานะ","prop":"status"}},[_c('el-select',{staticClass:"width-100",attrs:{"placeholder":"สถานะ"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_c('div',{attrs:{"slot":"prefix"},slot:"prefix"},[_c('i',{staticClass:"fa-solid fa-circle",style:(`color:${_vm.getpaymentConfirmStatusColor(
                        _vm.form.status
                      )};`)})]),_vm._l((_vm.paymentConfirmStatus),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.label,"value":item.name}},[_c('span',[_c('i',{staticClass:"fa-solid fa-circle",style:(`color:${item.color};margin-right:5px`)}),_vm._v(" "+_vm._s(item.label))])])})],2)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"float-right"},[_c('el-button',{attrs:{"loading":_vm.loadingList,"type":"success","size":"medium"},on:{"click":_vm.search}},[_vm._v("ค้นหา")])],1)])],1)],1)],1),_c('div',{staticClass:"admin-list-box"},[_c('el-card',[_c('h3',[_vm._v("รายการชำระเงิน")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingList),expression:"loadingList"}],staticClass:"user-list"},[_vm._l((_vm.paymentConfirms),function(item){return _c('el-row',{key:item.id,staticClass:"user-item",attrs:{"gutter":20},on:{"click":function($event){return _vm.fetchPaymentConfirmDetail(item.id)}}},[_c('el-col',{staticClass:"content-box",attrs:{"span":19}},[_c('div',{staticClass:"content-detail-box"},[_c('i',{staticClass:"fa-solid fa-credit-card"}),_c('div',[_c('p',[_vm._v(" "+_vm._s(item.payment_code)+" "),_c('el-tag',{style:(`background-color:${_vm.getpaymentConfirmStatusColor(
                          item.status
                        )};color:#fff;`)},[_vm._v(_vm._s(_vm.getpaymentConfirmStatusLabel(item.status)))])],1),_c('p',[_vm._v(" "+_vm._s(_vm._f("dateTHShort")(item.create_date))+" ")])])])]),_c('el-col',{staticClass:"action-box",attrs:{"span":5}},[_c('el-button',{attrs:{"type":"info","icon":"el-icon-edit-outline","size":"mini","circle":""},on:{"click":function($event){return _vm.fetchPaymentConfirmDetail(item.id)}}})],1)],1)}),(_vm.total > 0)?_c('div',{staticClass:"pagination-box"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"current-page":_vm.page,"page-size":_vm.limit},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.handleCurrentChange}})],1):_vm._e()],2)])],1)],1),(_vm.detail)?_c('el-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingDetail),expression:"loadingDetail"}],attrs:{"span":14}},[_c('el-card',{staticClass:"payment-detail-box"},[_c('h3',[_vm._v(_vm._s(_vm.detail.paymentConfirm.payment_code))]),_c('div',{staticClass:"payment-detail-content"},[_c('div',{staticClass:"image-box"},[_c('a',{staticClass:"fancybox",attrs:{"data-fancybox":"payment-preview","href":`${_vm.fileUploadURL}/${_vm.detail.paymentConfirm.img}`}},[_c('el-card',{staticClass:"box-card",attrs:{"shadow":"hover"}},[_c('el-image',{attrs:{"src":`${_vm.fileUploadURL}/${_vm.detail.paymentConfirm.img}`,"fit":"cover"}})],1)],1)]),_c('div',{staticClass:"data-box"},[_c('p',[_c('b',[_vm._v("วันที่ชำระ: ")]),_vm._v(" "+_vm._s(_vm._f("dateTHShort")(_vm.detail.paymentConfirm.create_date))+" ")]),_c('div',[_c('p',[_c('b',[_vm._v("สถานะ: ")])]),_c('el-form',{ref:"form-update",attrs:{"model":_vm.formUpdate}},[_c('el-form-item',{attrs:{"prop":"status"}},[_c('el-select',{staticClass:"width-100",attrs:{"placeholder":"สถานะ"},on:{"change":(status) =>
                        _vm.updatepaymentConfirmStatus(
                          _vm.detail.paymentConfirm.id,
                          _vm.detail.payment.payment_code,
                          _vm.detail.registerType.id,
                          status
                        )},model:{value:(_vm.formUpdate.status),callback:function ($$v) {_vm.$set(_vm.formUpdate, "status", $$v)},expression:"formUpdate.status"}},[_c('div',{attrs:{"slot":"prefix"},slot:"prefix"},[_c('i',{staticClass:"fa-solid fa-circle",style:(`color:${_vm.getpaymentConfirmStatusColor(
                          _vm.formUpdate.status
                        )};`)})]),_vm._l((_vm.paymentUpdateStatus),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.label,"value":item.name}},[_c('span',[_c('i',{staticClass:"fa-solid fa-circle",style:(`color:${item.color};margin-right:5px`)}),_vm._v(" "+_vm._s(item.label))])])})],2)],1)],1)],1),_c('div',{staticClass:"data-detail"},[_c('h3',[_vm._v("ข้อมูลการชำระเงิน")]),_c('p',[_c('b',[_vm._v("เลขใบ Payment:")]),_vm._v(" #"+_vm._s(_vm.detail.payment.payment_code))]),_c('h4',[_vm._v("วิธีการชำระเงิน")]),_c('p',[_c('i',{staticClass:"fa-regular fa-circle-check"}),_vm._v(" "+_vm._s(_vm.detail.payment.payment_type === "bank_transfer" ? "โอนเงินเข้าบัญชี" : "เช็คธนาคาร")+" ")]),_c('p',[_c('b',[_vm._v("จำนวนเงิน:")]),_vm._v(" "+_vm._s(_vm._f("comma")(_vm.detail.payment.total_price))+" บาท ")])])])]),_c('div',{staticClass:"register-box"},[_c('h3',[_vm._v("รายละเอียดผู้สมัคร")]),_c('div',{staticClass:"register-list"},_vm._l((_vm.detail.registers),function(item){return _c('div',{key:item.id,staticClass:"register-item"},[_c('div',{staticClass:"item"},[_c('p',[_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(item.register_code))]),_c('span',[_vm._v(" "+_vm._s(`${item.prefix_en} ${item.first_name_en} ${item.last_name_en}`)+" ")]),_c('span',{staticClass:"tag-label"},[_vm._v(_vm._s(_vm._f("commaBath")(item.registration_fees === "member" ? 3500 : 0)))])],1),_c('p',[_c('b',[_vm._v("ประเภท:")]),_vm._v(" "+_vm._s("แพทย์"))])]),_c('el-divider')],1)}),0)]),_c('div')])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }