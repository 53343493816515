<template>
  <div @click="$router.push('/')" class="header-form disabled-print">
    <el-image :src="require('@/assets/images/logo.png')"></el-image>
  </div>
</template>

<script>
export default {
  name: "HeaderForm",
};
</script>
