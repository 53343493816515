<template>
  <div class="bg-main">
    <el-container>
      <Header />
      <Home />
      <Register />
      <Accommodation />
      <Schedule />
      <ContactUs />
    </el-container>
    <Footer />
  </div>
</template>

<script>
import Header from "@/layout/Header";
import Footer from "@/layout/Footer";

import Home from "@/components/home/Home";
import Register from "@/components/home/Register";
import Accommodation from "@/components/home/Accommodation";
import Schedule from "@/components/home/Schedule";
import ContactUs from "@/components/home/ContactUs";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Home,
    Register,
    Accommodation,
    Schedule,
    ContactUs,
  },
  props: {
    msg: String,
  },
};
</script>
