<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="bg-main">
    <el-container>
      <HeaderForm />

      <div class="form-company">
        <div class="header-form-company">
          <h1>HOTEL RESERVATION</h1>
          <p>จองโรงแรม</p>
        </div>
      </div>

      <div v-if="closeReservation" class="form-payment">
        <h1>หมดระยะเวลาในการจองห้องพักแล้ว</h1>
        <p>หากต้องการสอบถามข้อมูลกรุณาติดต่อ</p>
        <p>โทร: 053-936452 , 089-4331363</p>
        <p>E-mail: admin@rb2024.net</p>
      </div>

      <div v-else>
        <el-form
          ref="formInfo"
          :model="formInfo"
          label-position="top"
          class="register-form"
        >
          <InfoForm :formInfoProp="formInfo" @updateFormInfo="updateFormInfo" />
        </el-form>

        <div class="form-payment">
          <PaymentForm />
        </div>

        <div class="action">
          <el-button @click="registerValidate" type="primary"
            >Register / ลงทะเบียน</el-button
          >
        </div>
      </div>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import HeaderForm from "@/layout/HeaderForm";
import Footer from "@/layout/Footer";

import InfoForm from "@/components/reservation/InfoForm";
import PaymentForm from "@/components/reservation/PaymentForm";

import { HTTP } from "@/plugins/axios.js";

export default {
  name: "Reservation-form",
  components: {
    Footer,

    InfoForm,
    PaymentForm,
    HeaderForm,
  },
  data() {
    return {
      closeReservation: true,
      formInfo: {
        prefixTH: "",
        nameTH: "",
        lastNameTH: "",
        prefixEN: "",
        nameEN: "",
        lastNameEN: "",
        companyName: "",
        addressType: "",
        address: "",
        district: "",
        amphoe: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        tel: "",
        fax: "",
        email: "",
        hotelName: "The Empress Chiang Mai",
        superiorRoom: 0,
        deluxeRoom: 0,
        keyManRoom: 0,
        extraBedRoom: 0,
        superiorTwinRoom: 0,
        deluxeTwinRoom: 0,
        keyManTwinRoom: 0,
        extraBedTwinRoom: 0,
        checkInDate: "",
        checkOutDate: "",
        arrivalDate: "",
        arrivalFightNo: "",
        departureDate: "",
        departureFightNo: "",
      },
      fullscreenLoading: false,
    };
  },

  methods: {
    updateFormInfo(key, value) {
      console.log("updateFormInfo", key, value);
      this.formInfo[key] = value;
      if (
        key === "district" ||
        key === "amphoe" ||
        key === "city" ||
        key === "zipcode"
      ) {
        this.$refs["formInfo"].validateField(`district`);
        this.$refs["formInfo"].validateField(`amphoe`);
        this.$refs["formInfo"].validateField(`city`);
        this.$refs["formInfo"].validateField(`zipcode`);
      }
      console.log("this.formInfo", this.formInfo);
    },
    registerValidate() {
      this.$refs["formInfo"].validate((valid) => {
        if (valid) {
          this.reservation();
        }
      });
    },
    async reservation() {
      this.fullscreenLoading = true;

      let obj = {
        institution: this.formInfo.companyName,
        address: this.formInfo.address,
        address_type: this.formInfo.addressType,
        district: this.formInfo.district,
        amphoe: this.formInfo.amphoe,
        city: this.formInfo.city,
        country: this.formInfo.country,
        email: this.formInfo.email,
        fax: this.formInfo.fax,
        first_name: this.formInfo.nameTH,
        last_name: this.formInfo.lastNameTH,
        first_name_en: this.formInfo.nameTH,
        last_name_en: this.formInfo.lastNameEN,
        prefix: this.formInfo.prefixTH,
        prefix_en: this.formInfo.prefixEN,
        state: this.formInfo.state,
        telephone: this.formInfo.tel,
        zipcode: this.formInfo.zipcode,
        hotel_name: this.formInfo.hotelName,
        superior_room: this.formInfo.superiorRoom,
        deluxe_room: this.formInfo.deluxeRoom,
        key_man_room: this.formInfo.keyManRoom,
        extra_bed: this.formInfo.extraBedRoom,
        superior_twin_room: this.formInfo.superiorTwinRoom,
        deluxe_twin_room: this.formInfo.deluxeTwinRoom,
        key_man_twin_room: this.formInfo.keyManTwinRoom,
        extra_twin_bed: this.formInfo.extraBedTwinRoom,
        checkin_date: this.formInfo.checkInDate,
        checkout_date: this.formInfo.checkOutDate,
        arrival_date: this.formInfo.arrivalDate,
        arrival_fight_no: this.formInfo.arrivalFightNo,
        departure_date: this.formInfo.departureDate,
        departure_fight_no: this.formInfo.departureFightNo,
      };

      let res = await HTTP.post(`/reservation/create`, obj);

      if (res.data.success) {
        this.$router.push(`/reservation-payment/${res.data.data}`);
      } else {
        if (res.data.message === "Email duplicate") {
          this.$message({
            message: `อีเมลถูกใช้งานแล้ว ${res.data.data.toString()}`,
            type: "warning",
          });
        }
      }

      this.fullscreenLoading = false;
    },
  },
};
</script>
