<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="bg-main">
    <el-container>
      <HeaderForm />

      <div class="payment-confirm">
        <h1>แบบฟอร์มส่งหลักฐานการชำระเงิน</h1>
        <el-form :model="form">
          <div class="serach-box">
            <el-input
              v-model="form.code"
              placeholder="กรอกเลขลงทะเบียน"
            ></el-input>
            <el-button type="success" @click="searchPayment">ค้นหา</el-button>
          </div>

          <div v-if="!payment" class="payment-alert">
            <el-alert
              title="ไม่พบข้อมูล"
              type="error"
              description="ไม่พบข้อมูล กรุณาตรวจสอบเลขใบ PAYMENT"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <div
            v-else-if="payment.status === 'รอตรวจสอบการชำระเงิน'"
            class="payment-alert"
          >
            <el-alert
              :title="payment.status"
              type="warning"
              description="ทำการยื่นหลักฐานการชำระเงินแล้ว ระบบจะทำการตรวจสอบแล้วส่งข้อมูลกลับทางอีกเมล์โดยเร็วหากไม่พบเมลกรุณาตรวจสอบที่จดหมายขยะ"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <div
            v-else-if="payment.status === 'ชำระเงินสำเร็จ'"
            class="payment-alert"
          >
            <el-alert
              :title="payment.status"
              type="success"
              description="ทำการชำระเงินเรียบร้อยแล้ว ระบบได้ทำการส่งเมลยืนยันไปยังอีเมลของท่านหากไม่พบเมลกรุณาตรวจสอบที่จดหมายขยะ"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <div
            v-else-if="payment.status === 'ชำระเงินไม่สำเร็จ'"
            class="payment-alert"
          >
            <el-alert
              :title="payment.status"
              type="error"
              description="กรุณาส่งหลักฐานการโอนเงินอีกครั้ง"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <div
            v-if="
              payment &&
              (payment.status === 'ยังไม่ได้ยื่นหลักฐานการชำระเงิน' ||
                payment.status === 'ชำระเงินไม่สำเร็จ')
            "
          >
            <el-divider></el-divider>

            <el-form-item
              label="หลักฐานการโอนเงิน"
              prop="upload"
              class="upload"
            >
              <div class="upload-box">
                <input
                  @change="onUpload"
                  type="file"
                  name="profile"
                  id="profile"
                  class="dp-contents"
                  accept="image/*,.pdf"
                />
              </div>
            </el-form-item>

            <div v-if="form.preview && this.form.upload" class="preview">
              <el-image :src="form.preview">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i></div
              ></el-image>
            </div>
            <div class="text-left">
              <el-button type="primary" @click="uploadFile"
                >ส่งหลักฐานการชำระเงิน</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import HeaderForm from "@/layout/HeaderForm";
import Footer from "@/layout/Footer";

import { HTTP } from "@/plugins/axios.js";

export default {
  name: "PaymentConfirmPage",
  components: {
    Footer,
    HeaderForm,
  },

  data() {
    return {
      form: {
        code: "",
        upload: "",
        preview: "",
      },
      fullscreenLoading: true,
      payment: null,
    };
  },
  mounted() {
    if (this.$route.query.code) {
      this.form.code = this.$route.query.code;
      this.searchPayment();
    } else {
      this.fullscreenLoading = false;
    }
  },
  methods: {
    onUpload(e) {
      const file = e.target.files[0];

      this.form.upload = file;
      this.form.preview = URL.createObjectURL(file);

      // this.$refs["ruleForm"].validateField("upload");
    },
    async searchPayment() {
      let res = await HTTP.get(`/payment/status/${this.form.code}`);

      if (res.data.success) {
        this.payment = res.data.data;
      }

      this.fullscreenLoading = false;
    },
    async uploadFile() {
      if (this.form.upload) {
        this.fullscreenLoading = true;
        let formData = new FormData();
        formData.append("file", this.form.upload);
        formData.append("code", this.form.code);
        let res = await HTTP.post(`/payment/upload`, formData);

        if (res.data.success) {
          this.payment["status"] = res.data.data.statusPayment;
        }

        this.fullscreenLoading = false;
      } else {
        this.$message({
          message: "กรุณาอัปโหลดไฟล์",
          type: "warning",
        });
      }
    },
  },
};
</script>
