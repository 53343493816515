<template>
  <section id="accommodation" class="page-box">
    <h1 class="title">{{ $t("menu.accommodation") }}</h1>
    <div class="accommodation-list">
      <el-card class="box-card" shadow="hover">
        <img
          :src="require('@/assets/images/accommodation1.jpg')"
          class="image"
        />
        <div class="body">
          <h2>The Empress Chiang Mai</h2>
          <div class="detail">
            <p>
              199, 42 Changklan Rd, Tambon Chang Khlan, Mueang Chiang Mai
              District, Chiang Mai 50100
            </p>
            <p>Tel. 053 253 199</p>
          </div>
          <div class="booking">
            <el-button @click="$router.push('/reservation')" type="primary">{{
              $t("accommodation.btnBooking")
            }}</el-button>
            <!-- <a
              data-fancybox="booking-3"
              :href="require('@/assets/images/booking.png')"
              class="fancybox"
              data-options="{'loop':true,'buttons':['download','zoom','thumbs','close']}"
              data-type="image"
            >
              <el-button type="primary">{{
                $t("accommodation.btnBooking")
              }}</el-button>
            </a> -->
          </div>
        </div>
      </el-card>
    </div>
    <el-row class="accommodation-search">
      <el-row :gutter="20"
        ><el-col :sm="24" :md="12">
          <el-button @click="searchPaymentInvoice" type="primary">{{
            $t("register.findPaymentInvoice")
          }}</el-button></el-col
        ><el-col :sm="24" :md="12">
          <el-button @click="searchSubmitProof" type="success">{{
            $t("register.submitProofOfPayment")
          }}</el-button></el-col
        ></el-row
      >
    </el-row>
  </section>
</template>

<script>
export default {
  name: "AccommodationComponent",
  props: {
    msg: String,
  },
  methods: {
    searchPaymentInvoice() {
      this.$prompt("", this.$t("accommodation.fillFindPaymentInvoice"), {
        confirmButtonText: this.$t("message.btnSearch"),
        cancelButtonText: this.$t("message.btnCancel"),
        inputPattern: /[\w]+/,
        inputErrorMessage: "Please fill  Payment invoice code",
      })
        .then(({ value }) => {
          this.$router.push(`/reservation-payment/${value}`);
        })
        .catch(() => {});
    },
    searchSubmitProof() {
      this.$prompt("", this.$t("accommodation.fillSubmitProofOfPayment"), {
        confirmButtonText: this.$t("message.btnSearch"),
        cancelButtonText: this.$t("message.btnCancel"),
        inputPattern: /[\w]+/,
        inputErrorMessage: "Please fill Submit proof of payment",
      })
        .then(({ value }) => {
          this.$router.push(`/reservation-payment-confirm?code=${value}`);
        })
        .catch(() => {});
    },
  },
};
</script>
