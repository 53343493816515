<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="bg-main">
    <el-container>
      <HeaderForm />

      <div v-if="bill" class="payment-detail">
        <div class="payment-section top">
          <div class="payment-header">
            <div class="payment-header-left">
              <el-image
                :src="require('@/assets/images/logo-second.png')"
                class="logo"
              ></el-image>
            </div>

            <div class="payment-header-center">
              <h3>CHIANG MAI UNIVERSITY NEPHROLOGY</h3>
              <h3>HCONFERENCE 2024</h3>
              <h3>December 5-7 , 2024</h3>
              <p>หน่วยวิชาโรคไต ภาควิชาอายุรศาสตร์</p>
              <p>คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่</p>
            </div>

            <div class="payment-header-right">
              <p>(สำหรับผู้จอง)</p>
              <p>โปรดเก็บส่วนนี้ไว้เป็นหลักฐานการชำระเงิน</p>
              <p>วันที่ {{ bill.reservation.create_date | dateNumber }}</p>
            </div>
          </div>
          <div class="payment-header-sub">
            <div class="payment-header-sub-left">
              <p>
                รหัสการจอง/RESERVATION CODE :
                {{ bill.reservation.reservation_code }}
              </p>
              <p>ชื่อโรงแรม: {{ bill.reservationInfo.hotel_name }}</p>
              <p>จำนวน: {{ totalNight }} คืน</p>
            </div>
            <div class="payment-header-sub-right">
              <p>หน่วยงาน/Institution: {{ bill.reservation.institution }}</p>
              <p>
                วันที่เช็คอิน:

                {{ bill.reservationInfo.checkin_date | dateNumber }}
              </p>
              <p>
                วันที่เช็คเอาท์:
                {{ bill.reservationInfo.checkout_date | dateNumber }}
              </p>
            </div>
          </div>
          <div class="payment-table-list">
            <div class="table-header">
              <el-row :gutter="20">
                <el-col :span="10">
                  <h3>รายการ</h3>
                </el-col>
                <el-col :span="4">
                  <h3>Single/พักเดี่ยว</h3>
                </el-col>
                <el-col :span="4">
                  <h3>Twin/พักคู่</h3>
                </el-col>
                <el-col :span="6">
                  <h3>ราคา</h3>
                </el-col>
              </el-row>
            </div>
            <div class="table-body">
              <el-row
                v-for="item in paymentList"
                :key="item.id"
                :gutter="20"
                class="table-item"
              >
                <el-col :span="10">
                  <p>{{ item.name }}</p>
                </el-col>
                <el-col :span="4" class="center">
                  <p>{{ item.singleNumber }}</p>
                </el-col>
                <el-col :span="4" class="center">
                  <p>{{ item.twinNumber }}</p>
                </el-col>
                <el-col :span="6" class="right">
                  <p>
                    {{
                      ((item.singlePrice * item.singleNumber +
                        item.twinPrice * item.twinNumber) *
                        totalNight)
                        | commaDecimal
                    }}
                  </p>
                </el-col>
              </el-row>
            </div>
            <div class="table-footer">
              <el-row :gutter="20">
                <el-col :span="18">
                  <h3>จำนวนเงินรวม : {{ total | thaiBaht }}</h3>
                </el-col>

                <el-col :span="6" class="right">
                  <h3>{{ total | commaDecimal }}</h3>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="payment-sign">
            <div class="payment-sign-box"></div>
            <div class="payment-sign-item">
              เจ้าหน้าที่ธนาคาร
              <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              <span class="payment-sign-name">(ผู้รับเงิน)</span>
            </div>
          </div>
          <div class="payment-note">
            <span class="payment-note-label"> หมายเหตุ: </span>
            <span class="payment-note-text">
              โปรดตรวจสอบรายละเอียดให้ครบถ้วนก่อนชำระเงิน<br />ทางโครงการขอสงวนสิทธิ์ในการคืนเงินทุกกรณี</span
            >
          </div>
        </div>
        <div class="payment-section bottom">
          <div class="payment-header">
            <div class="payment-header-left">
              <el-image
                :src="require('@/assets/images/logo-second.png')"
                class="logo"
              ></el-image>
            </div>

            <div class="payment-header-center">
              <h3>CHIANG MAI UNIVERSITY NEPHROLOGY</h3>
              <h3>HCONFERENCE 2024</h3>
              <h3>December 5-7 , 2024</h3>
              <p>หน่วยวิชาโรคไต ภาควิชาอายุรศาสตร์</p>
              <p>คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่</p>
            </div>

            <div class="payment-header-right">
              <p>(สำหรับธนาคาร)</p>
              <p>โปรดเรียกเก็บเงินค่าธรรมเนียมจาก</p>
              <p>ผู้ชำระเงิน</p>
            </div>
          </div>
          <div class="payment-header-sub">
            <h3>ใบแจ้งการชำระเงินผ่านธนาคาร / Bank Bill Payment</h3>
          </div>
          <div class="payment-table-list">
            <div class="table-pre-header">
              <el-row :gutter="20">
                <el-col :span="12">
                  <p>
                    ธนาคารไทยพาณิชย์ สาขาคณะแพทยศาสตร์ มช.<br />
                    ชื่อบัญชี หน่วยวิชาโรคไต<br />
                    บัญชี ออมทรัพย์<br />
                    เลขที่บัญชี 566-2-50644-2
                  </p>
                </el-col>
                <el-col :span="12">
                  <p>
                    วันที่ / Date {{ bill.reservation.create_date | dateNumber
                    }}<br />
                    หน่วยงาน/Institution {{ bill.reservation.institution
                    }}<br />
                    รหัสชำระเงิน/PAYMENT CODE :
                    {{ bill.reservation.reservation_code }}<br />
                  </p>
                </el-col>
              </el-row>
            </div>
            <div class="table-header">
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>
                    <i class="far fa-square"></i>
                    เช็ค / Cheque
                  </p>
                </el-col>
                <el-col :span="6">
                  <p>หมายเลขเช็ค / Cheque No.</p>
                </el-col>
                <el-col :span="6">
                  <p>ชื่อธนาคาร / Bnak-Branch</p>
                </el-col>
                <el-col :span="6">
                  <p>เช็คลงวันที่ / Due Date</p>
                </el-col>
              </el-row>
            </div>
            <div class="table-header">
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>
                    <i class="far fa-check-square"></i>
                    เงินสด / Cash
                  </p>
                </el-col>
                <el-col :span="6"
                  ><p>
                    {{ "&nbsp;" }}
                  </p></el-col
                >
                <el-col :span="6"
                  ><p>
                    {{ "&nbsp;" }}
                  </p></el-col
                >
                <el-col :span="6"> <p>&nbsp;</p></el-col>
              </el-row>
            </div>
            <div class="table-footer border-bottom">
              <el-row :gutter="20">
                <el-col :span="9">
                  <p>จำนวนเงิน(บาท) / Amount (Baht)</p>
                </el-col>

                <el-col :span="15" class="right">
                  <p>{{ total | commaDecimal }}</p>
                </el-col>
              </el-row>
            </div>
            <div class="table-footer">
              <el-row :gutter="20">
                <el-col :span="9">
                  <p>จำนวนเงินเป็นตัวอักษร / Amount in Words</p>
                </el-col>

                <el-col :span="15" class="right">
                  <p>({{ total | thaiBaht }})</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="payment-sign">
            <div class="payment-sign-box">
              <div class="payment-sign-item">
                ผู้นำฝาก
                <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              </div>
              <div class="payment-sign-item">
                โทร.
                <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              </div>
            </div>
            <div class="payment-sign-item">
              เจ้าหน้าที่ธนาคาร
              <span class="payment-sign-line">&nbsp;&nbsp;&nbsp;</span><br />
              <span class="payment-sign-name">(ผู้รับเงิน)</span>
            </div>
          </div>
          <div class="payment-note">
            <span class="payment-note-label"> หมายเหตุ: </span>
            <span class="payment-note-text">
              มีค่าธรรมเนียมการชำระเงินตามระเบียบของธนาคาร</span
            >
          </div>
        </div>
      </div>
      <div v-if="bill" class="action disabled-print">
        <el-button @click="print" type="success">พิมพ์เอกสาร</el-button>
        <el-button
          @click="
            $router.push(
              `/reservation-payment-confirm?code=${$route.params.code}`
            )
          "
          type="primary"
          >ยื่นหลักฐานการชำระเงิน</el-button
        >
      </div>

      <div v-if="!bill" class="not-found">
        <h1>ไม่มีข้อมูล, กรุณาตรวจสอบรหัสชำระเงินอีกครั้ง</h1>
      </div>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import HeaderForm from "@/layout/HeaderForm";
import Footer from "@/layout/Footer";

import { HTTP } from "@/plugins/axios.js";

export default {
  name: "ReservationPayment",
  components: {
    Footer,
    HeaderForm,
  },
  computed: {
    total() {
      let total = 0;
      this.paymentList.map((item) => {
        let sum =
          item.singlePrice * item.singleNumber +
          item.twinPrice * item.twinNumber;

        total += sum;
      });

      return total * this.totalNight;
    },

    totalNight() {
      if (
        !this.bill ||
        !this.bill.reservationInfo.checkin_date ||
        !this.bill.reservationInfo.checkout_date
      ) {
        return 0;
      }

      let start = new Date(this.bill.reservationInfo.checkin_date).setHours(
        0,
        0,
        0,
        0
      );
      let end = new Date(this.bill.reservationInfo.checkout_date).setHours(
        0,
        0,
        0,
        0
      );

      let day = (end - start) / 86400000;

      return day;
    },
  },
  data() {
    return {
      paymentList: [
        {
          id: "superior",
          name: "Superior Room",
          singlePrice: 2000,
          twinPrice: 2000,
          singleNumber: 0,
          twinNumber: 0,
        },
        {
          id: "deluxe",
          name: "Deluxe Room",
          singlePrice: 2600,
          twinPrice: 2600,
          singleNumber: 0,
          twinNumber: 0,
        },
        {
          id: "keyMan",
          name: "Key man Room",
          singlePrice: 3200,
          twinPrice: 3200,
          singleNumber: 0,
          twinNumber: 0,
        },
        {
          id: "extraBed",
          name: "Extra Bed",
          singlePrice: 1000,
          twinPrice: 1000,
          singleNumber: 0,
          twinNumber: 0,
        },
      ],
      bill: "",
      fullscreenLoading: true,
    };
  },
  mounted() {
    this.fetchReservation();
  },
  methods: {
    print() {
      window.print();
    },
    async fetchReservation() {
      let res = await HTTP.get(`/reservation/get/${this.$route.params.code}`);

      if (res.data.success) {
        this.bill = res.data.data;
        this.paymentList = [
          {
            id: "superior",
            name: "Superior Room",
            singlePrice: 2000,
            twinPrice: 2000,
            singleNumber: res.data.data.reservationInfo.superior_room,
            twinNumber: res.data.data.reservationInfo.superior_twin_room,
          },
          {
            id: "deluxe",
            name: "Deluxe Room",
            singlePrice: 2600,
            twinPrice: 2600,
            singleNumber: res.data.data.reservationInfo.deluxe_room,
            twinNumber: res.data.data.reservationInfo.deluxe_twin_room,
          },
          {
            id: "keyMan",
            name: "Key man Room",
            singlePrice: 3200,
            twinPrice: 3200,
            singleNumber: res.data.data.reservationInfo.key_man_room,
            twinNumber: res.data.data.reservationInfo.key_man_twin_room,
          },
          {
            id: "extraBed",
            name: "Extra Bed",
            singlePrice: 1000,
            twinPrice: 1000,
            singleNumber: res.data.data.reservationInfo.extra_bed,
            twinNumber: res.data.data.reservationInfo.extra_twin_bed,
          },
        ];
      }

      this.fullscreenLoading = false;
    },
  },
};
</script>
