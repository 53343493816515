<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-form-item
        label="Payment การชําระเงิน"
        prop="paymentType"
        :rules="[
          {
            required: true,
            message: 'Please select Method of payment',
            trigger: 'change',
          },
        ]"
      >
        <el-select
          v-model="form.paymentType"
          placeholder="Method of payment / วิธีการชำระเงิน"
          @change="updateFormPayment('paymentType', form.paymentType)"
          class="width-100"
        >
          <el-option
            v-for="item in paymentTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col v-if="form.paymentType === 'cheque'" :sm="24" :md="12">
      <el-form-item prop="bankName">
        <el-input
          v-model="form.bankName"
          placeholder="Bank (Full Bank Name) / ธนาคาร (ชื่อเต็ม)"
          @input="updateFormPayment('bankName', form.bankName)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col v-if="form.paymentType === 'cheque'" :sm="24" :md="12">
      <el-form-item prop="branch">
        <el-input
          v-model="form.branch"
          placeholder="Branch / สาขา"
          @input="updateFormPayment('branch', form.branch)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col v-if="form.paymentType === 'cheque'" :sm="24" :md="12">
      <el-form-item prop="accountNumber">
        <el-input
          v-model="form.accountNumber"
          placeholder="Account Number / เลขที่"
          @input="updateFormPayment('accountNumber', form.accountNumber)"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col v-if="form.paymentType === 'cheque'" :sm="24" :md="12">
      <el-form-item prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          placeholder="Date / วันที่"
          class="width-100"
          @change="updateFormPayment('date', form.date)"
        >
        </el-date-picker>
      </el-form-item>
    </el-col>
    <el-col v-if="form.paymentType === 'cheque'" :span="24" class="text-left">
      <span class="text-dark">สั่งจ่ายในนาม หน่วยวิชาโรคไต</span>
    </el-col>
    <el-col v-if="form.paymentType === 'bank_transfer'" :span="24">
      <div class="bank-account">
        <p>
          ชื่อบัญชี หน่วยวิชาโรคไต เลขที่บัญชี 566-2-50644-2 ธนาคารไทยพาณิชย์
          สาขา คณะแพทยศาสตร์ มช. บัญชี ออมทรัพย์
        </p>
        <p>
          Payments can be made in US$ by bank transfer to the following bank
          account: Bank account No. 566-2-50644-2 The Siam Commercial Bank
          Public Company Limited, Faculty of Medicine Chiang Mai branch,Chiang
          Mai 50200, Thailand
        </p>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "PaymentFormComponent",
  data() {
    return {
      form: {
        paymentType: "",
        bankName: "",
        branch: "",
        accountNumber: "",
        date: "",
      },
      paymentTypes: [
        { id: "cheque", name: "Cheque / เช็ค" },
        { id: "bank_transfer", name: "Bank Transfer / โอนเงิน" },
      ],
    };
  },

  methods: {
    updateFormPayment(key, value) {
      this.$emit("updateFormPayment", key, value);
      if (key === "paymentType") {
        this.form.date = new Date();
        this.$emit("paymentType", "date", this.form.date);
      }
    },
  },
};
</script>
