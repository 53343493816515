var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"width-100"},_vm._l((_vm.members),function(member,index){return _c('div',{key:index,staticClass:"form-info"},[(index > 0)?_c('div',{staticClass:"remove"},[_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.$emit('removeMember', index)}}})]):_vm._e(),_vm._m(0,true),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.prefixTH',"rules":[
            {
              required: true,
              message: 'กรุณาเลือกคำนำหน้านาม',
              trigger: 'change',
            },
          ]}},[_c('el-select',{staticClass:"width-100",attrs:{"placeholder":"คำนำหน้านาม"},on:{"change":function($event){return _vm.updateFormInfo('prefixTH', member.prefixTH, index)}},model:{value:(member.prefixTH),callback:function ($$v) {_vm.$set(member, "prefixTH", $$v)},expression:"member.prefixTH"}},_vm._l((_vm.prefixs),function(item){return _c('el-option',{key:item.nameTH,attrs:{"label":item.nameTH,"value":item.nameTH}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":9}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.nameTH',"rules":[
            {
              required: true,
              message: 'กรุณากรอกชื่อ',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"ชื่อ"},on:{"input":function($event){return _vm.updateFormInfo('nameTH', member.nameTH, index)}},model:{value:(member.nameTH),callback:function ($$v) {_vm.$set(member, "nameTH", $$v)},expression:"member.nameTH"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":9}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.lastNameTH',"rules":[
            {
              required: true,
              message: 'กรุณากรอกนามสกุล',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"นามสกุล"},on:{"input":function($event){return _vm.updateFormInfo('lastNameTH', member.lastNameTH, index)}},model:{value:(member.lastNameTH),callback:function ($$v) {_vm.$set(member, "lastNameTH", $$v)},expression:"member.lastNameTH"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.prefixEN',"rules":[
            {
              required: true,
              message: 'Please select Prefix',
              trigger: 'change',
            },
          ]}},[_c('el-select',{staticClass:"width-100",attrs:{"placeholder":"Prefix"},on:{"change":function($event){return _vm.updateFormInfo('prefixEN', member.prefixEN, index)}},model:{value:(member.prefixEN),callback:function ($$v) {_vm.$set(member, "prefixEN", $$v)},expression:"member.prefixEN"}},_vm._l((_vm.prefixs),function(item){return _c('el-option',{key:item.nameEN,attrs:{"label":item.nameEN,"value":item.nameEN}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":9}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.nameEN',"rules":[
            {
              required: true,
              message: 'Please input Name',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"Name"},on:{"input":function($event){return _vm.updateFormInfo('nameEN', member.nameEN, index)}},model:{value:(member.nameEN),callback:function ($$v) {_vm.$set(member, "nameEN", $$v)},expression:"member.nameEN"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":9}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.lastNameEN',"rules":[
            {
              required: true,
              message: 'Please input Last Name',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"Last Name"},on:{"input":function($event){return _vm.updateFormInfo('lastNameEN', member.lastNameEN, index)}},model:{value:(member.lastNameEN),callback:function ($$v) {_vm.$set(member, "lastNameEN", $$v)},expression:"member.lastNameEN"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"เลขที่ประกอบวิชาชีพ","prop":'members.' + index + '.professionalNo',"rules":[
            {
              required: true,
              message: 'Please input Professional No',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"เลขที่ประกอบวิชาชีพ"},on:{"input":function($event){return _vm.updateFormInfo('professionalNo', member.professionalNo, index)}},model:{value:(member.professionalNo),callback:function ($$v) {_vm.$set(member, "professionalNo", $$v)},expression:"member.professionalNo"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"Organization,Company หน่วยงาน, สถาบัน","prop":'members.' + index + '.companyName',"rules":[
            {
              required: true,
              message: 'Please input Organization,Company',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"Organization,Company หน่วยงาน, สถาบัน"},on:{"input":function($event){return _vm.updateFormInfo('companyName', member.companyName, index)}},model:{value:(member.companyName),callback:function ($$v) {_vm.$set(member, "companyName", $$v)},expression:"member.companyName"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('p',{staticClass:"description"}),_c('el-form-item',{attrs:{"prop":'members.' + index + '.addressType',"rules":[
            {
              required: true,
              message: 'Please input Address Type',
              trigger: 'blur',
            },
          ]}},[_c('el-select',{staticClass:"width-100",attrs:{"placeholder":"Address Type / ประเภทที่อยู่"},on:{"change":function($event){return _vm.updateFormInfo('addressType', member.addressType, index)}},model:{value:(member.addressType),callback:function ($$v) {_vm.$set(member, "addressType", $$v)},expression:"member.addressType"}},_vm._l((_vm.addressTypes),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":16}},[_c('p',{staticClass:"description"},[_vm._v(" (โปรดระบุ บ้านเลขที่,หมู่บ้าน,ซอย หรือรายละเอียดอื่นๆให้ครบถ้วน) ")]),_c('el-form-item',{attrs:{"prop":'members.' + index + '.address',"rules":[
            {
              required: true,
              message: 'Please input Address',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"Address / ที่อยู่"},on:{"input":function($event){return _vm.updateFormInfo('address', member.address, index)}},model:{value:(member.address),callback:function ($$v) {_vm.$set(member, "address", $$v)},expression:"member.address"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.district',"rules":[
            {
              required: true,
              message: 'Please input District',
              trigger: 'blur',
            },
          ]}},[_c('el-autocomplete',{staticClass:"width-100",attrs:{"popper-class":"popper-auto-address","fetch-suggestions":(queryString, cb) => _vm.querySearch(queryString, cb, 'district'),"placeholder":"District / ตำบล"},on:{"select":(value) => _vm.handleSelectAddress(value, index),"input":function($event){return _vm.updateFormInfo('district', member.district, index)}},model:{value:(member.district),callback:function ($$v) {_vm.$set(member, "district", $$v)},expression:"member.district"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.amphoe',"rules":[
            {
              required: true,
              message: 'Please input Amphoe',
              trigger: 'blur',
            },
          ]}},[_c('el-autocomplete',{staticClass:"width-100",attrs:{"popper-class":"popper-auto-address","fetch-suggestions":(queryString, cb) => _vm.querySearch(queryString, cb, 'amphoe'),"placeholder":"Amphoe / อำเภอ"},on:{"select":(value) => _vm.handleSelectAddress(value, index),"input":function($event){return _vm.updateFormInfo('amphoe', member.amphoe, index)}},model:{value:(member.amphoe),callback:function ($$v) {_vm.$set(member, "amphoe", $$v)},expression:"member.amphoe"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.city',"rules":[
            {
              required: true,
              message: 'Please input City',
              trigger: 'blur',
            },
          ]}},[_c('el-autocomplete',{staticClass:"width-100",attrs:{"popper-class":"popper-auto-address","fetch-suggestions":(queryString, cb) => _vm.querySearch(queryString, cb, 'city'),"placeholder":"City / จังหวัด"},on:{"select":(value) => _vm.handleSelectAddress(value, index),"input":function($event){return _vm.updateFormInfo('city', member.city, index)}},model:{value:(member.city),callback:function ($$v) {_vm.$set(member, "city", $$v)},expression:"member.city"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":"state"}},[_c('el-input',{attrs:{"placeholder":"State / รัฐ"},on:{"input":function($event){return _vm.updateFormInfo('state', member.state, index)}},model:{value:(member.state),callback:function ($$v) {_vm.$set(member, "state", $$v)},expression:"member.state"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.city',"rules":[
            {
              required: true,
              message: 'Please input Country',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"Country / ประเทศ"},on:{"input":function($event){return _vm.updateFormInfo('country', member.country, index)}},model:{value:(member.country),callback:function ($$v) {_vm.$set(member, "country", $$v)},expression:"member.country"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.zipcode',"rules":[
            {
              required: true,
              message: 'Please input Zip code',
              trigger: 'blur',
            },
          ]}},[_c('el-autocomplete',{staticClass:"width-100",attrs:{"popper-class":"popper-auto-address","fetch-suggestions":(queryString, cb) => _vm.querySearch(queryString, cb, 'zipcode'),"placeholder":"Zip / รหัสไปรษณี"},on:{"select":(value) => _vm.handleSelectAddress(value, index),"input":function($event){return _vm.updateFormInfo('zipcode', member.zipcode, index)}},model:{value:(member.zipcode),callback:function ($$v) {_vm.$set(member, "zipcode", $$v)},expression:"member.zipcode"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.tel',"rules":[
            {
              required: true,
              message: 'Please input Tel',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"placeholder":"Tel / โทรศัพท์"},on:{"input":function($event){return _vm.updateFormInfo('tel', member.tel, index)}},model:{value:(member.tel),callback:function ($$v) {_vm.$set(member, "tel", $$v)},expression:"member.tel"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8,"prop":"fax"}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"Fax / โทรสาร"},on:{"input":function($event){return _vm.updateFormInfo('fax', member.fax, index)}},model:{value:(member.fax),callback:function ($$v) {_vm.$set(member, "fax", $$v)},expression:"member.fax"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.email',"rules":[
            {
              required: true,
              message: 'Please input Email',
              trigger: 'blur',
            },
            { validator: _vm.checkEmail, trigger: 'blur' },
          ]}},[_c('el-input',{attrs:{"placeholder":"E-mail / อีเมล"},on:{"input":function($event){return _vm.updateFormInfo('email', member.email, index)}},model:{value:(member.email),callback:function ($$v) {_vm.$set(member, "email", $$v)},expression:"member.email"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":'members.' + index + '.registrationFees',"label":"Registration Fees ค่าลงทะเบียน","rules":[
            {
              required: true,
              message: 'Please select Registration Fees',
              trigger: 'change',
            },
          ]}},[_c('el-select',{staticClass:"width-100",attrs:{"placeholder":"Registration fee type / ประเภท"},on:{"change":function($event){return _vm.updateFormInfo(
                'registrationFees',
                member.registrationFees,
                index
              )}},model:{value:(member.registrationFees),callback:function ($$v) {_vm.$set(member, "registrationFees", $$v)},expression:"member.registrationFees"}},_vm._l((_vm.registrationFees),function(item){return _c('el-option',{key:item.id,attrs:{"label":`${item.name} (${_vm.$options.filters.comma(
                item.price
              )} บาท)`,"value":item.id}})}),1)],1)],1)],1)],1)}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-form-info"},[_c('h1',[_vm._v("Registrant Information ข้อมูลผู้ลงทะเบียน")]),_c('p',[_vm._v("If you don't have any information, Please fill - in the blanks.")]),_c('p',[_vm._v("(หากคุณไม่มีข้อมูล โปรดกรอกเครื่องหมาย - ในช่องว่าง)")])])
}]

export { render, staticRenderFns }