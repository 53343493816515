<template>
  <div class="width-100">
    <div v-for="(member, index) in members" :key="index" class="form-info">
      <div v-if="index > 0" class="remove">
        <i @click="$emit('removeMember', index)" class="el-icon-error"></i>
      </div>
      <div class="header-form-info">
        <h1>Registrant Information ข้อมูลผู้ลงทะเบียน</h1>
        <p>If you don't have any information, Please fill - in the blanks.</p>
        <p>(หากคุณไม่มีข้อมูล โปรดกรอกเครื่องหมาย - ในช่องว่าง)</p>
      </div>

      <el-row :gutter="20">
        <el-col :sm="24" :md="6">
          <el-form-item
            :prop="'members.' + index + '.prefixTH'"
            :rules="[
              {
                required: true,
                message: 'กรุณาเลือกคำนำหน้านาม',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="member.prefixTH"
              placeholder="คำนำหน้านาม"
              @change="updateFormInfo('prefixTH', member.prefixTH, index)"
              class="width-100"
            >
              <el-option
                v-for="item in prefixs"
                :key="item.nameTH"
                :label="item.nameTH"
                :value="item.nameTH"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            :prop="'members.' + index + '.nameTH'"
            :rules="[
              {
                required: true,
                message: 'กรุณากรอกชื่อ',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.nameTH"
              placeholder="ชื่อ"
              @input="updateFormInfo('nameTH', member.nameTH, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            :prop="'members.' + index + '.lastNameTH'"
            :rules="[
              {
                required: true,
                message: 'กรุณากรอกนามสกุล',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.lastNameTH"
              placeholder="นามสกุล"
              @input="updateFormInfo('lastNameTH', member.lastNameTH, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="6">
          <el-form-item
            :prop="'members.' + index + '.prefixEN'"
            :rules="[
              {
                required: true,
                message: 'Please select Prefix',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="member.prefixEN"
              placeholder="Prefix"
              @change="updateFormInfo('prefixEN', member.prefixEN, index)"
              class="width-100"
            >
              <el-option
                v-for="item in prefixs"
                :key="item.nameEN"
                :label="item.nameEN"
                :value="item.nameEN"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            :prop="'members.' + index + '.nameEN'"
            :rules="[
              {
                required: true,
                message: 'Please input Name',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.nameEN"
              placeholder="Name"
              @input="updateFormInfo('nameEN', member.nameEN, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="9">
          <el-form-item
            :prop="'members.' + index + '.lastNameEN'"
            :rules="[
              {
                required: true,
                message: 'Please input Last Name',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.lastNameEN"
              placeholder="Last Name"
              @input="updateFormInfo('lastNameEN', member.lastNameEN, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="เลขที่ประกอบวิชาชีพ"
            :prop="'members.' + index + '.professionalNo'"
            :rules="[
              {
                required: true,
                message: 'Please input Professional No',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.professionalNo"
              placeholder="เลขที่ประกอบวิชาชีพ"
              @input="
                updateFormInfo('professionalNo', member.professionalNo, index)
              "
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item
            label="Organization,Company หน่วยงาน, สถาบัน"
            :prop="'members.' + index + '.companyName'"
            :rules="[
              {
                required: true,
                message: 'Please input Organization,Company',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.companyName"
              placeholder="Organization,Company หน่วยงาน, สถาบัน"
              @input="updateFormInfo('companyName', member.companyName, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <p class="description"></p>
          <el-form-item
            :prop="'members.' + index + '.addressType'"
            :rules="[
              {
                required: true,
                message: 'Please input Address Type',
                trigger: 'blur',
              },
            ]"
          >
            <el-select
              v-model="member.addressType"
              placeholder="Address Type / ประเภทที่อยู่"
              @change="updateFormInfo('addressType', member.addressType, index)"
              class="width-100"
            >
              <el-option
                v-for="item in addressTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="16">
          <p class="description">
            (โปรดระบุ บ้านเลขที่,หมู่บ้าน,ซอย หรือรายละเอียดอื่นๆให้ครบถ้วน)
          </p>
          <el-form-item
            :prop="'members.' + index + '.address'"
            :rules="[
              {
                required: true,
                message: 'Please input Address',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.address"
              placeholder="Address / ที่อยู่"
              @input="updateFormInfo('address', member.address, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            :prop="'members.' + index + '.district'"
            :rules="[
              {
                required: true,
                message: 'Please input District',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="member.district"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'district')
              "
              placeholder="District / ตำบล"
              @select="(value) => handleSelectAddress(value, index)"
              @input="updateFormInfo('district', member.district, index)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            :prop="'members.' + index + '.amphoe'"
            :rules="[
              {
                required: true,
                message: 'Please input Amphoe',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="member.amphoe"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'amphoe')
              "
              placeholder="Amphoe / อำเภอ"
              @select="(value) => handleSelectAddress(value, index)"
              @input="updateFormInfo('amphoe', member.amphoe, index)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            :prop="'members.' + index + '.city'"
            :rules="[
              {
                required: true,
                message: 'Please input City',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="member.city"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'city')
              "
              placeholder="City / จังหวัด"
              @select="(value) => handleSelectAddress(value, index)"
              @input="updateFormInfo('city', member.city, index)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item prop="state">
            <el-input
              v-model="member.state"
              placeholder="State / รัฐ"
              @input="updateFormInfo('state', member.state, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            :prop="'members.' + index + '.city'"
            :rules="[
              {
                required: true,
                message: 'Please input Country',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.country"
              placeholder="Country / ประเทศ"
              @input="updateFormInfo('country', member.country, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            :prop="'members.' + index + '.zipcode'"
            :rules="[
              {
                required: true,
                message: 'Please input Zip code',
                trigger: 'blur',
              },
            ]"
          >
            <el-autocomplete
              class="width-100"
              popper-class="popper-auto-address"
              v-model="member.zipcode"
              :fetch-suggestions="
                (queryString, cb) => querySearch(queryString, cb, 'zipcode')
              "
              placeholder="Zip / รหัสไปรษณี"
              @select="(value) => handleSelectAddress(value, index)"
              @input="updateFormInfo('zipcode', member.zipcode, index)"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            :prop="'members.' + index + '.tel'"
            :rules="[
              {
                required: true,
                message: 'Please input Tel',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="member.tel"
              placeholder="Tel / โทรศัพท์"
              @input="updateFormInfo('tel', member.tel, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8" prop="fax">
          <el-form-item>
            <el-input
              v-model="member.fax"
              placeholder="Fax / โทรสาร"
              @input="updateFormInfo('fax', member.fax, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item
            :prop="'members.' + index + '.email'"
            :rules="[
              {
                required: true,
                message: 'Please input Email',
                trigger: 'blur',
              },
              { validator: checkEmail, trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="member.email"
              placeholder="E-mail / อีเมล"
              @input="updateFormInfo('email', member.email, index)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            :prop="'members.' + index + '.registrationFees'"
            label="Registration Fees ค่าลงทะเบียน"
            :rules="[
              {
                required: true,
                message: 'Please select Registration Fees',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="member.registrationFees"
              placeholder="Registration fee type / ประเภท"
              @change="
                updateFormInfo(
                  'registrationFees',
                  member.registrationFees,
                  index
                )
              "
              class="width-100"
            >
              <el-option
                v-for="item in registrationFees"
                :key="item.id"
                :label="`${item.name} (${$options.filters.comma(
                  item.price
                )} บาท)`"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  searchAddressByDistrict,
  searchAddressByAmphoe,
  searchAddressByProvince,
  searchAddressByZipcode,
} from "thai-address-database";

export default {
  name: "InfoFormComponent",
  props: ["members"],
  data() {
    return {
      prefixs: [
        { nameTH: "นพ.", nameEN: "Prof." },
        { nameTH: "พญ.", nameEN: "Dr." },
        { nameTH: "นาย", nameEN: "Mr." },
        { nameTH: "นาง", nameEN: "Ms." },
        { nameTH: "นางสาว", nameEN: "Mrs." },
      ],
      registrationFees: [
        { id: "member", name: "Doctor, Nurse / แพทย์, พยาบาล", price: 3500 },
      ],
      addressTypes: [
        { id: "home", name: "Home / บ้าน" },
        { id: "office", name: "Office / สำนักงาน" },
      ],
    };
  },

  methods: {
    checkEmail(rule, value, callback) {
      if (!value) {
        return callback(new Error("Please input email"));
      } else {
        let regx = /^[\wก-ฮ-.]+@([\wก-ฮ-]+.)+[\wก-ฮ-]{2,4}$/g;
        let match = regx.test(value);
        if (match) {
          callback();
        } else {
          callback(new Error("Please input correct email"));
        }
      }
    },
    querySearch(queryString, cb, type) {
      var results = [];

      switch (type) {
        case "district":
          results = searchAddressByDistrict(queryString);
          break;
        case "amphoe":
          results = searchAddressByAmphoe(queryString);
          break;
        case "city":
          results = searchAddressByProvince(queryString);
          break;
        case "zipcode":
          results = searchAddressByZipcode(queryString);
          break;
        default:
          break;
      }

      var resultsConvert = results.map((item) => {
        let value = `${item.district} » ${item.amphoe} » ${item.province} » ${item.zipcode}`;
        return { value: value, data: item };
      });

      cb(resultsConvert);
    },
    handleSelectAddress(value, index) {
      console.log("value", value);
      this.updateFormInfo("district", value.data.district, index);
      this.updateFormInfo("amphoe", value.data.amphoe, index);
      this.updateFormInfo("city", value.data.province, index);
      this.updateFormInfo("zipcode", value.data.zipcode, index);
    },
    updateFormInfo(key, value, index) {
      this.$emit("updateFormInfo", key, value, index);
    },
  },
};
</script>
