<template>
  <div class="admin-template">
    <div class="header-admin">
      <span><i class="fa-solid fa-circle-user"></i> Administrator</span>
      <span @click="logout" class="logout-button">
        <i class="fa-solid fa-right-from-bracket"></i> ออกจากระบบ
      </span>
    </div>
    <el-row :gutter="20" class="admin-box">
      <el-col :span="4"
        ><div class="nav-menu">
          <div
            v-for="item in navMenu"
            :key="item.name"
            class="nav-item"
            :class="{ active: item.name === $route.name }"
            @click="goToPage(item.url)"
          >
            <span> {{ item.label }}</span>
            <span class="nav-icon"> <i :class="item.icon"></i></span>
          </div></div
      ></el-col>
      <el-col :span="20">
        <div class="nav-content">
          <slot></slot>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { auth } from "@/maxins/auth";

export default {
  name: "AdminTemplate",
  components: {},
  mixins: [auth],
  data() {
    return {
      navMenu: [
        {
          name: "AdminDashboard",
          label: "หน้าหลัก",
          icon: "fa-solid fa-gauge-high",
          url: "/admin-dashboard",
        },
        {
          name: "AdminPayment",
          label: "รายการชำระเงิน",
          icon: "fa-solid fa-file-invoice-dollar",
          url: "/admin-payment",
        },
        {
          name: "AdminRegistered",
          label: "ข้อมูลการลงทะเบียน",
          icon: "fa-solid fa-address-card",
          url: "/admin-registered",
        },
        {
          name: "AdminPaymentConfirm",
          label: "ยืนยันการชำระเงิน",
          icon: "fa-solid fa-clipboard-check",
          url: "/admin-payment-confirm",
        },
        {
          name: "AdminReservation",
          label: "ข้อมูลการจองที่พัก",
          icon: "fa-solid fa-hotel",
          url: "/admin-reservation",
        },
      ],
    };
  },
  methods: {
    goToPage(url) {
      this.$router.push(url).catch((f) => {
        console.error(f);
      });
    },
  },
};
</script>
