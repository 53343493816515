<template>
  <div class="footer disabled-print">
    <footer>Copyright &copy; 2024 All Rights Reserved</footer>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  components: {},
  props: {
    msg: String,
  },
};
</script>
